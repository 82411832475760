import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import { Layout, HeroPost, SectionGrid } from "../components/common";
import { MetaData } from "../components/common/meta";
import * as pageStyles from '../styles/page.module.css'
import { ContactForm } from '../components/common/index'

/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 */
const Page = ({ data, location }) => {
    const page = data.ghostPage;
    const posts = data.allGhostPost.edges;

    return (
        <>
            <MetaData data={data} location={location} type="website" />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                {
                    ['opinion', 'wikihow', 'canal'].includes(page.slug) ?
                        (
                            <div className={pageStyles.page}>
                                <HeroPost filterTag={page.slug} />
                                <SectionGrid tag={page.slug} posts={posts} context={'page'} />
                            </div>
                        )
                        : page.slug === 'nosotros' ? (
                            <div className={pageStyles.page}>
                                {/* Other content */}
                            </div>
                        )
                            : page.slug === 'contacto' ? (
                                <div className={pageStyles.page}>
                                    <ContactForm />
                                </div>
                            ) : ['politica-privacidad', 'terminos-de-servicio', 'accesibilidad', 'codigo-de-conducta'].includes(page.slug) ? (
                                <article className={pageStyles.legal}>
                                    <header>
                                        <h1>
                                            {
                                                page.title
                                            }
                                        </h1>
                                    </header>
                                    <section dangerouslySetInnerHTML={{__html: page.html}} />
                                </article>
                            ) : null

                }
            </Layout>
        </>
    );
};

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Page;

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
